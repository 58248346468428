import { useEffect } from 'react';
import css from "./RequireAssistancePage.module.css"
import { modelSpeak, pointBotRight, setLive2DScale } from "../../Helper/UnityLive2DController"

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


const RequireAssistancePage = ({ setSpeech }) => {

  const { t } = useTranslation();

  useEffect(() => {
    setLive2DScale(-0.75, 0.75, 0.75);
    setSpeech(t('requireAssistance.welcome'));
    modelSpeak(20);
    pointBotRight();
  }, []);

  return (
    <>
      <div className = {css.title}>
        {t('requireAssistance.message')}
      </div>
      <Link to="/">
        <div className={css.backHomeButton}>
          <div className={css.backHomeButtonText}>
            {t('global.homePage')}
          </div>
        </div>
      </Link>
    </>
  );

}

export default RequireAssistancePage;