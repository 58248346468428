import css from './Header.module.css';
import LogoHA from './logo_ha.png';
import LogoUC from './logo_uc.png';

// Logo deserve its own component because it has to be dynamically fetched
const Header = () => {
  return (
    <div className = {css.headerContainer}>
      <img src = {LogoHA} className = {css.leftLogo} />
      <img src = {LogoUC} className = {css.rightLogo} />
    </div>
  );
}

export default Header;